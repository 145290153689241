<template lang="pug">
  div
    el-card(body-style="padding:0")
      div(slot="header")
        el-form(inline v-if="can(`balances.${this.filters.type}`)")
          el-form-item(label="")
            el-input(placeholder="Ara..." v-model="search" clearable)
              i.el-input__icon.el-icon-search(slot="prefix")
          el-form-item(label="")
            el-select(v-model="filters.displayCurrency" placeholder="Para birimi" style="width: 120px" @change="fetch(false)" disabled)
              el-option(v-for="c in $store.state.currencies" :key="c" :value="c" :label="c")
          el-form-item(label="")
              el-button(@click="fetch" :loading="fetching" icon="el-icon-tickets") Getir
              el-button(@click="fetch(true)" icon="el-icon-download") Excel
      el-tabs.pl-10(v-model="filters.type" @tab-click="fetch" align="center" stretch)
        el-tab-pane(name="agency" label="ACENTALAR" v-if="hasFeature('agencies')" lazy)
        el-tab-pane(name="guide" label="REHBERLER" v-if="hasFeature('guides')" lazy)
        el-tab-pane(name="taxidriver" label="TAKSİCİLER" v-if="hasFeature('taxis')" lazy)
        el-tab-pane(name="dealer" label="HANUTÇULAR" v-if="hasFeature('dealers')" lazy)
        el-tab-pane(name="seller" label="SATICILAR" lazy)
        el-tab-pane(name="supplier" label="TEDARİKÇİLER" lazy)
        el-tab-pane(name="customer" label="MÜŞTERİLER" v-if="hasFeature('customers')" lazy)
      el-alert(
        type="warning"
        title="Bu bölümü kullanma yetkiniz yok"
        v-if="!can(`balances.${filters.type}`)"
        show-icon
        :closable="false"
        center
      )
      el-table(
        :data="filteredReport"
        show-summary
        :summary-method="summary"
        size="mini"
        stripe
        :height="$store.state.windowHeight-182"
        v-if="report.length && filters.type !== 'supplier' && can(`balances.${filters.type}`)"
        :default-sort = "{ prop: 'company_name', order: 'ascending' }"
      )
        el-table-column(
          prop="company_name"
          :label="filters.type === 'guide' ? 'Acenta': 'Taksi firması'"
          v-if="['guide', 'taxidriver'].includes(filters.type)"
          sortable
          width="100px"
          show-overflow-tooltip
        )
        el-table-column(prop="name" :label="types[filters.type]" sortable width="170px" show-overflow-tooltip)
          template(v-slot="props")
            el-popover(
              placement="bottom"
              title="Komisyon oranları"
              width="200"
              trigger="click"
            )

              el-tag(size="small" slot="reference").mr-5: i.el-icon-info
            span {{ props.row.name }}
        el-table-column(prop="margin" label="OKP, %" width="100px" align="center" class-name="text-primary" v-if="filters.type === 'agency'" sortable)
        el-table-column(label="PERFORMANS" align="center" class-name="text-success" v-if="filters.type !== 'seller'")
          el-table-column(prop="initial_pax" label="Gelen pax" width="150px" align="right" class-name="text-success" sortable)
          el-table-column(prop="pax" label="Giren pax" width="150px" align="right" class-name="text-success" sortable)
          el-table-column(prop="pax_avg" :label="`Pax Ort. ${currency}`" width="150px" align="right" class-name="text-success" sortable)
          el-table-column(prop="sale" :label="`Toplam ciro, ${currency}`" width="150px" align="right" class-name="text-success" sortable)
        el-table-column(
          prop="sale"
          :label="`Toplam ciro, ${filters.displayCurrency}`"
          width="150px"
          align="right"
          class-name="text-success"
          v-if="filters.type === 'seller'"
          sortable
        )
        el-table-column(prop="account_transfer" label="Devir" align="right" width="150px" class-name="text-danger" sortable)
        el-table-column(:label="`HAKEDİŞLER, ${filters.displayCurrency}`" align="center" class-name="text-primary")
          el-table-column(prop="total" label="Toplam" align="right" width="150px" class-name="text-primary" sortable)
          el-table-column(prop="paid" label="Ödenen" align="right" width="150px" class-name="text-primary" sortable)
          el-table-column(prop="left" label="Kalan" align="right" width="150px" class-name="text-danger" sortable)
        el-table-column(prop="left" label="Ödeme yap" align="center" width="150px" class-name="text-danger")
          template(v-slot="props")
            el-button(size="mini" icon="el-icon-money" disabled) Ödeme yap
</template>
<script>
  import collect from 'collect.js'
  import Type from '@/models/Type'
  import User from '@/models/User'
  import Company from '@/models/Company'
  export default {
    name: 'balances',
    data(){
      return {
        types: {
          agency: 'Acenta',
          guide: 'Rehber',
          taxidriver: 'Taksici',
          dealer: 'Hanutçu',
          seller: 'Satıcı',
          customer: 'Müşteri',
        },
        companies: [],
        clients: [],
        report: [],
        filters: {
          type: '',
          displayCurrency: process.env.VUE_APP_DEFAULT_CURRENCY || 'USD'
        },
        fetching: false,
        search: ''
      }
    },
    created(){
      if (this.hasFeature('agencies')) {
        this.filters.type = 'agency'
      } else {
        this.filters.type = 'seller'
      }
      Company.where('type', 'agency')
        .get()
        .then(data => { this.companies = data })
      User.include(['roles', 'company'])
        .whereIn('roles', ['guide', 'dealer', 'taxi', 'seller'])
        .get()
        .then(data => { this.clients = data })
      Type.where('category', 'payment')
        .get()
        .then(data => { this.paymentTypes = data })
      this.fetch()
    },
    methods: {
      async fetch(excel = false) {
        if (this.can(`balances.${this.filters.type}`)) {
          this.report = []
          let params = excel === true ? { excel, ...this.filters } : this.filters;
          params.branch_id = this.$store.state.branch.id
          try {
            this.fetching = true
            let { data } = await axios.get('reports/balances', { params })
            this.report = data
            this.fetching = false
          } catch(e) {
            this.fetching = false
            this.$message.error(e.message)
          }
        }
      },
      summary({ columns, data }){
        data = collect(data)
        let initialPax = data.sum('initial_pax')
        let pax        = data.sum('pax')
        let sale       = data.sum('sale')
        let paxAvg     = sale !== 0 && pax !== 0 ? sale/pax : 0
        let totalTrasfer = data.sum('account_transfer')
        let totalComm  = data.sum('total')
        let paidComm   = data.sum('paid')
        let leftComm   = data.sum('left')
        let output = ['']
        if(['guide', 'taxidriver'].includes(this.filters.type)){
          output.push('')
        }
        output.push('TOPLAMLAR')
        if(this.filters.type !== 'seller'){
          output.push(initialPax + ' pax')
          output.push(pax + ' pax')
          output.push(paxAvg.toFixed(2) + ' ' + this.currency)
        }
        output.push(sale.toFixed(2) + ' ' + this.currency)
        output.push(totalTrasfer.toFixed(2) + ' ' + this.currency)
        output.push(totalComm.toFixed(2) + ' ' + this.currency)
        output.push(paidComm.toFixed(2) + ' ' + this.currency)
        output.push(leftComm.toFixed(2) + ' ' + this.currency)
        return output
      }
    },
    computed: {
      currency() {
        return this.$store.state.currency
      },
      filteredReport() {
        return this.report.filter(r => (
          r.name.toLowerCase().includes(this.search.toLowerCase()) ||
          (r.company_name && r.company_name.toLowerCase().includes(this.search.toLowerCase()))
        ))
      }
    }
  }
</script>